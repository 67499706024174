<template>
  <div
    class="project__container"
    :class="{
      'no-data': projectLists.length === 0
    }"
  >
    <div
      class="project__menus-container unfold"
      :class="{
        vHidden: subMenusOpen
      }"
    >
      <template v-if="$bowser.mobile">
        <div class="menu__current-show" v-if="currentType">
          <p>
            {{ currentType.name.en }} | <span>{{ currentType.name.cn }}</span>
          </p>
          <i class="icon"></i>
        </div>
        <div class="menu__list-wrapper" v-height ref="subMenuWrapper">
          <div class="menu__row" ref="mobileMenuRows" v-for="(item, index) in menus" :key="index">
            <router-link
              class="menu__item"
              tag="div"
              :class="{
                actived: item.id === typeID,
                disabled: !item.clickable
              }"
              :to="{
                path: item.clickable ? `/projects/${item.id}` : $route.fullPath
              }"
            >
              <div class="item__link">
                <p>{{ item.name.en }}</p>
                <p>{{ item.name.cn }}</p>
              </div>
            </router-link>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="menu__row" v-for="(row, index) in menus" :key="index">
          <router-link
            class="menu__item pcHover"
            tag="div"
            v-for="(item, i) in row"
            :key="i"
            :class="{
              actived: item.id === typeID,
              disabled: !item.clickable
            }"
            :to="{
              path: item.clickable ? `/projects/${item.id}` : $route.fullPath
            }"
          >
            <div class="item__link">
              <p>{{ item.name.en }}</p>
              <p>{{ item.name.cn }}</p>
            </div>
          </router-link>
        </div>
      </template>
    </div>
    <div class="project__lists-container">
      <div class="lists__inner">
        <template v-if="requesting">
          <div class="loading" style="display:none;">
            <i></i>
            <p>加载中...</p>
          </div>
        </template>
        <template>
          <template v-if="projectLists.length > 0">
            <index-shape
              :lists="projectLists"
              ref="indexComponent"
              v-if="$route.name === 'router-project'"
              @imgLoaded="onImgLoaded"
            ></index-shape>
            <transition
              @enter="onListsEnter"
              @before-leave="onListsBeforeLeave"
              @leave="onListsLeave"
            >
              <list-shape
                @imgLoaded="onImgLoaded"
                v-if="showLists"
                :lists="projectLists"
              ></list-shape>
            </transition>
          </template>
          <template v-if="projectLists.length === 0 && !requesting">
            <p>没有数据</p>
          </template>
        </template>
      </div>
    </div>
    <div
      class="info__switch__bt"
      :class="{
        normal: !showLists,
        actived: showLists,
        vHidden: subMenusOpen,
        pcHover: !$bowser.mobile
      }"
      ref="isb"
      @click="showLists = !showLists"
    >
      <div class="icon__wrapper">
        <svg class="bt__icon" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 16 16">
          <rect width="2" height="2"></rect>
          <rect x="5" width="2" height="2"></rect>
          <rect x="10" width="2" height="2"></rect>
          <rect y="5" width="2" height="2"></rect>
          <rect x="5" y="5" width="2" height="2"></rect>
          <rect x="10" y="5" width="2" height="2"></rect>
          <rect y="10" width="2" height="2"></rect>
          <rect x="5" y="10" width="2" height="2"></rect>
          <rect x="10" y="10" width="2" height="2"></rect>
        </svg>
      </div>
      <p class="bt__text">
        <template v-if="$bowser.mobile">
          <span>{{ showLists ? "pictures" : "gallery" }}</span>
        </template>
        <template v-else>
          <span>{{ showLists ? "view pictures" : "view gallery" }}</span>
          <span>{{ showLists ? "查看图片" : "查看列表" }}</span>
        </template>
      </p>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import gsap from 'gsap';
import { mapState } from 'vuex';
import { projects } from '@/api/data';
import indexShape from './_index.vue';
import listShape from './_list.vue';
import events from '@/utils/eventBus';

const tl = gsap.timeline();

export default {
  name: 'views-project',
  components: {
    indexShape,
    listShape,
  },
  data() {
    return {
      lists: [],
      lists2: [],
      projectLists: [],
      showLists: false,
      currentShape: 'index',
      requesting: false,
      page: 1,
      subMenuUnfold: false,
      totalRowsHeight: 0,
    };
  },
  directives: {
    height: {
      bind: () => {},
      inserted: (el) => {
        const height = el.querySelector('.menu__row').offsetHeight * el.querySelectorAll('.menu__row').length;
        el.setAttribute('data-height', height);
      },
    },
  },
  computed: {
    ...mapState(['subMenusOpen', 'productTypes']),
    menus() {
      if (this.productTypes.length > 0) {
        const temp = [];
        if (this.$bowser.mobile) {
          return this.typeID
            ? this.productTypes.filter((item) => Number(item.id) !== this.typeID)
            : this.productTypes;
        }
        for (let i = 0; i < this.productTypes.length; i += 3) {
          temp.push(this.productTypes.slice(i, i + 3));
        }
        return temp;
      }
      return [];
    },
    typeID() {
      return Number(this.$route.params.typeID);
    },
    currentType() {
      if (this.productTypes.length > 0) {
        return this.productTypes.find((item) => Number(item.id) === this.typeID);
      }
      return {};
    },
  },
  watch: {
    typeID: {
      handler() {
        this.showLists = false;
        this.projectLists = [];
        this.getmore();
      },
      immediate: true,
    },
  },
  mounted() {
    const that = this;
    that.$on('firstImgOffsetLeft', (offset) => {
      that.$refs.isb.style.left = offset > 0 ? `${offset}px` : '40%';
      that.$refs.isb.style.opacity = 1;
    });
    that.$nextTick(() => {
      events.$emit('pageMounted');
    });
  },
  updated() {},
  methods: {
    onImgLoaded() {
      this.requesting = false;
      this.$nextTick(() => {
        this.pageInit();
      });
    },
    onListsEnter(el, done) {
      const that = this;
      done();
      setTimeout(() => {
        // el.classList.add('enter');
        if (that.$refs.indexComponent) {
          tl.fromTo(
            that.$refs.indexComponent.$el,
            {
              y: 0,
              opacity: 1,
            },
            {
              y: '100vh',
              opacity: 0,
              display: 'none',
              duration: 0.5,
            },
          );
        }

        tl.fromTo(
          el,
          {
            y: '100vh',
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 0.1,
            onComplete: () => {
              that.$nextTick(() => {
                document.documentElement.scrollTop = 0;
                that.pageInit();
              });
            },
          },
        );
      }, 10);
    },
    onListsBeforeLeave(el) {
      const that = this;
      tl.fromTo(
        el,
        {
          y: 0,
          opacity: 1,
        },
        {
          y: '100vh',
          opacity: 0,
          duration: 0.5,
          onComplete: () => {
            that.$nextTick(() => {
              document.documentElement.scrollTop = 0;
            });
          },
        },
      );
      if (that.$refs.indexComponent) {
        tl.set(that.$refs.indexComponent.$el, {
          display: 'block',
        });
        tl.fromTo(
          that.$refs.indexComponent.$el,
          {
            y: '100vh',
            opacity: 0,
          },
          {
            y: 0,
            opacity: 1,
            duration: 1,
          },
        );
      }
    },
    onListsLeave(el, done) {
      setTimeout(() => {
        done();
      }, 800);
    },
    getmore() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        projects(that.typeID, that.page)
          .then((res) => {
            const { data } = res;
            that.projectLists = data.list;
            if (that.page === 1 && data.list.length === 0) {
              that.requesting = false;
            }
            // if (that.page === 1) {

            // }
            events.$emit('pageMounted');
          })
          .finally(() => {
            // that.requesting = false;
          });
      }
    },
    getUnfold() {
      this.subMenuUnfold = !this.subMenuUnfold;
      this.$refs.subMenuWrapper.style.cssText = `height: ${
        this.subMenuUnfold ? this.$refs.subMenuWrapper.dataset.height : 0
      }px`;
    },
  },
};
</script>

<style></style>
