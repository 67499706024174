var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project__container",class:{
    'no-data': _vm.projectLists.length === 0
  }},[_c('div',{staticClass:"project__menus-container unfold",class:{
      vHidden: _vm.subMenusOpen
    }},[(_vm.$bowser.mobile)?[(_vm.currentType)?_c('div',{staticClass:"menu__current-show"},[_c('p',[_vm._v(" "+_vm._s(_vm.currentType.name.en)+" | "),_c('span',[_vm._v(_vm._s(_vm.currentType.name.cn))])]),_c('i',{staticClass:"icon"})]):_vm._e(),_c('div',{directives:[{name:"height",rawName:"v-height"}],ref:"subMenuWrapper",staticClass:"menu__list-wrapper"},_vm._l((_vm.menus),function(item,index){return _c('div',{key:index,ref:"mobileMenuRows",refInFor:true,staticClass:"menu__row"},[_c('router-link',{staticClass:"menu__item",class:{
              actived: item.id === _vm.typeID,
              disabled: !item.clickable
            },attrs:{"tag":"div","to":{
              path: item.clickable ? ("/projects/" + (item.id)) : _vm.$route.fullPath
            }}},[_c('div',{staticClass:"item__link"},[_c('p',[_vm._v(_vm._s(item.name.en))]),_c('p',[_vm._v(_vm._s(item.name.cn))])])])],1)}),0)]:_vm._l((_vm.menus),function(row,index){return _c('div',{key:index,staticClass:"menu__row"},_vm._l((row),function(item,i){return _c('router-link',{key:i,staticClass:"menu__item pcHover",class:{
            actived: item.id === _vm.typeID,
            disabled: !item.clickable
          },attrs:{"tag":"div","to":{
            path: item.clickable ? ("/projects/" + (item.id)) : _vm.$route.fullPath
          }}},[_c('div',{staticClass:"item__link"},[_c('p',[_vm._v(_vm._s(item.name.en))]),_c('p',[_vm._v(_vm._s(item.name.cn))])])])}),1)})],2),_c('div',{staticClass:"project__lists-container"},[_c('div',{staticClass:"lists__inner"},[(_vm.requesting)?[_vm._m(0)]:_vm._e(),[(_vm.projectLists.length > 0)?[(_vm.$route.name === 'router-project')?_c('index-shape',{ref:"indexComponent",attrs:{"lists":_vm.projectLists},on:{"imgLoaded":_vm.onImgLoaded}}):_vm._e(),_c('transition',{on:{"enter":_vm.onListsEnter,"before-leave":_vm.onListsBeforeLeave,"leave":_vm.onListsLeave}},[(_vm.showLists)?_c('list-shape',{attrs:{"lists":_vm.projectLists},on:{"imgLoaded":_vm.onImgLoaded}}):_vm._e()],1)]:_vm._e(),(_vm.projectLists.length === 0 && !_vm.requesting)?[_c('p',[_vm._v("没有数据")])]:_vm._e()]],2)]),_c('div',{ref:"isb",staticClass:"info__switch__bt",class:{
      normal: !_vm.showLists,
      actived: _vm.showLists,
      vHidden: _vm.subMenusOpen,
      pcHover: !_vm.$bowser.mobile
    },on:{"click":function($event){_vm.showLists = !_vm.showLists}}},[_c('div',{staticClass:"icon__wrapper"},[_c('svg',{staticClass:"bt__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"-2 -2 16 16"}},[_c('rect',{attrs:{"width":"2","height":"2"}}),_c('rect',{attrs:{"x":"5","width":"2","height":"2"}}),_c('rect',{attrs:{"x":"10","width":"2","height":"2"}}),_c('rect',{attrs:{"y":"5","width":"2","height":"2"}}),_c('rect',{attrs:{"x":"5","y":"5","width":"2","height":"2"}}),_c('rect',{attrs:{"x":"10","y":"5","width":"2","height":"2"}}),_c('rect',{attrs:{"y":"10","width":"2","height":"2"}}),_c('rect',{attrs:{"x":"5","y":"10","width":"2","height":"2"}}),_c('rect',{attrs:{"x":"10","y":"10","width":"2","height":"2"}})])]),_c('p',{staticClass:"bt__text"},[(_vm.$bowser.mobile)?[_c('span',[_vm._v(_vm._s(_vm.showLists ? "pictures" : "gallery"))])]:[_c('span',[_vm._v(_vm._s(_vm.showLists ? "view pictures" : "view gallery"))]),_c('span',[_vm._v(_vm._s(_vm.showLists ? "查看图片" : "查看列表"))])]],2)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"loading",staticStyle:{"display":"none"}},[_c('i'),_c('p',[_vm._v("加载中...")])])}]

export { render, staticRenderFns }